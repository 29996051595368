import React from 'react';
import { SEO, Layout } from "components"

import Form from './Form';

import styles from './styles.module.scss';

const Enrolement = () => (
  <Layout>
    <SEO title="Enrol with Albany OSHC" />
    <div className={styles.enrolementPage}>
      <div className={styles.container}>
        <h2>Enrol with Albany OSHC</h2>
        <p>
          Thank you for choosing Albany OSHC for your child care needs.
          To assist us in placing your child/ren,
          we ask that you fully complete the Enrolment Forms in this booklet and return them with the required documentation.
        </p>
        <p>
          These forms are to be completed every year to ensure our records are up-to-date and compliant.
          We look forward to supporting your family by providing education and care in a safe and fun environment.
        </p>
      </div>
      <div className={styles.container}>
        <Form />
      </div>
    </div>
  </Layout>
)

export default Enrolement;
